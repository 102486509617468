import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  }
}));

const tileData = [
  {
    img: "https://source.unsplash.com/random",
    title: "Image",
    author: "author",
    cols: 1,
    rows: 1
  },
  {
    img: "https://source.unsplash.com/collection/190629/",
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/collection/190723/",
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/collection/190684/",
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/collection/190725/",
    title: "Image",
    author: "author",
    cols: 1,
    rows: 1
  },
  {
    img: "https://source.unsplash.com/collection/190726/",
    title: "Image",
    author: "author",
    cols: 1
  }
];

export default function ImageGridList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList cellHeight={250} cols={1}>
        {tileData.map(tile => (
          <GridListTile key={tile.img} cols={tile.cols || 1} rows={tile.rows}>
            <img src={tile.img} alt={tile.title} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
