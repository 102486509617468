import React, { useState } from "react";
import { Link } from "react-router-dom";

//Added Component
function GreetComponent(props) {
  return (
    <h1>
      Component {props.name} {props.x}{" "}
    </h1>
  );
}

export default function Home() {
  //Array
  const arr = [
    {
      name: "mika",
      age: 34
    },
    {
      name: " moji",
      age: 30
    }
  ];
  return (
    <div className="App">
      <Link to="/new">NEW</Link>

      {/* Component with Props */}
      {/* Array */}
      {arr.map((val, ind) => (
        <h2 key={ind}>
          {val.name}
          {val.age}{" "}
        </h2>
      ))}
    </div>
  );
}
