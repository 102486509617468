import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

const useStyles = makeStyles(theme => ({
  root: {
    display: "grid",
    // flexWrap: "wrap",
    gridGap: 10,
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  }
}));
// const useStyles = makeStyles(theme => ({
//   root: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "space-around",
//     overflow: "hidden",
//     backgroundColor: theme.palette.background.paper
//   }
// }));

const tileData = [
  {
    img: "https://source.unsplash.com/random",
    title: "Image",
    author: "author",
    cols: 1,
    rows: 1
  },
  {
    img: "https://source.unsplash.com/collection/190629/",
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/collection/190723/",
    title: "Image",
    author: "author",
    cols: 2
  },
  {
    img: "https://source.unsplash.com/collection/190684/",
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/collection/190725/",
    title: "Image",
    author: "author",
    cols: 1,
    rows: 1
  },
  {
    img: "https://source.unsplash.com/collection/190726/",
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/collection/190726/",
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/collection/190726/",
    title: "Image",
    author: "author",
    cols: 2
  },
  {
    img: "https://source.unsplash.com/collection/785321/",
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: "https://source.unsplash.com/collection/765676/",
    title: "Image",
    author: "author",
    cols: 1
  }
];

export default function New() {
  const classes = useStyles();

  return (
    <div>
      <h1>Meine Pins</h1>
      <Link to="/home">to homes</Link>
      <Button variant="contained" color="primary">
        Hello World
      </Button>
      <Button variant="contained" color="primary">
        BIZZ
      </Button>
      <Button variant="contained" color="primary">
        WORK
      </Button>
      <Button variant="contained" color="primary">
        EAT
      </Button>
      <Button variant="contained" color="primary">
        FUN
      </Button>
      {/* <div className={classes.root}>
        <GridList cellHeight={260} cols={4}>
          {tileData.map(tile => (
            <GridListTile key={tile.img} cols={tile.cols || 1} rows={tile.rows}>
              <img src={tile.img} alt={tile.title} />
            </GridListTile>
          ))}
        </GridList>
      </div> */}
    </div>
  );
}
