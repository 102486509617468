export const stores = [
    {
        id: 0,
        name: 'La Katz',
        url: 'lakatz',
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt omnis ab voluptates quae eaque, autem laudantium unde consectetur? Voluptate, quasi.',
        img: 'https://images.unsplash.com/photo-1495240013514-62e89ff5a164?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
        img2: '',
        img3: ''
    },
    {
        id: 1,
        name: 'PEZOOO',
        url: 'pezoo',
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt omnis ab voluptates quae eaque, autem laudantium unde consectetur? Voluptate, quasi.',
        img: 'https://images.unsplash.com/photo-1545262722-9e0d80a0bc01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1079&q=80',
        img2: '',
        img3: ''
    },
    {
        id: 2,
        name: 'Ridsl',
        url: 'ridsl',
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt omnis ab voluptates quae eaque, autem laudantium unde consectetur? Voluptate, quasi.',
        img: 'https://images.unsplash.com/photo-1565720490564-4ca60b055af6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
        img2: 'https://images.unsplash.com/photo-1521017432531-fbd92d768814?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
        img3: 'https://images.unsplash.com/photo-1507914464562-6ff4ac29692f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'
    },
    {
        id: 3,
        name: 'Gredos',
        url: 'gredos',
        img: ''
    },
    {
        id: 4,
        name: 'Pögel',
        url: 'poegel',
        img: ''
    },
    {
        id: 5,
        name: 'Rumüs',
        url: 'rumues',
        img: ''
    }
]