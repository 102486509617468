import React from "react";
import { Link } from "react-router-dom";
import { stores } from "./DataShopping";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core/styles";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "sticky",
    bottom: 0
  }
});

export default ({ match }) => {
  // for dynamic routing
  const store = stores.find(element => element.id == match.params.id);

  console.log(match);
  console.log("hoopsh");
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <div className="storeLayout">
      <div className="storebanner">
        <h2>{store.name}</h2>
        {/* <Link to="/">home</Link> */}
        <img src={store.img} alt="" />
        <p> {store.description}</p>
        <div className="moreImages">
          <img src={store.img2} alt="" />
          <img src={store.img3} alt="" />
          <img src={store.img3} alt="" />
          <img src={store.img2} alt="" />
          <img src={store.img2} alt="" />
          <img src={store.img2} alt="" />
          <img src={store.img2} alt="" />
          <img src={store.img2} alt="" />
        </div>
        {/* <div className="spa">f</div> */}
      </div>
      {/* bottomNavbar start ***********************************/}
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
        <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
        <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
        <Link className="links" to="/">
          <BottomNavigationAction label="Start" icon={<CalendarViewDayIcon />} />
        </Link>
      </BottomNavigation>
      {/* bottomNavbar END *************************************/}
    </div>
  );
};
