import React from "react";
import { Link, useParams } from "react-router-dom";

export default function Greet() {
  const params = useParams();
  console.log(params.name);
  console.log(params);

  const name = "home";
  return (
    <div>
      <h1>Greet {params.name}</h1>
      <Link to={`/greet/${name}`}>to hi</Link>
    </div>
  );
}
