import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import GridTest from "./GridTest";
import New from "./New";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import Kategorien from "./Kategorien";
import "../App.css";
import Expansion from "./Expansion";
import "./expansion.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    // padding: 0,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  const [checked, setChecked] = React.useState(false);

  return (
    <div className={classes.root}>
      <AppBar className="toast" position="static" color="default" style={{ position: "sticky", top: 56, marginTop: 0 }}>
        <Typography style={{ textAlign: "center", marginTop: 10 }}>CITYNAME</Typography>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="full width tabs example">
          <Tab label="ENTDECKEN" {...a11yProps(0)} />
          <Tab label="AKTUELL" {...a11yProps(1)} />
          <Tab label="PINS" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {/* TAB 1 **********************************************************************/}
      <TabPanel style={{ paddingTop: 4 }} value={value} index={0} dir={theme.direction}>
        {/* <Slide direction="left" in={true} mountOnEnter unmountOnExit> */}
        <Fade in={true}>
          <div className="grid">
            {/* <Kategorien /> */}

            <Expansion style={{ background: "#04c681" }} name={"SHOPPING"} />
            <Expansion style={{ background: "#01A594" }} name={"ESSEN & TRINKEN"} />
            <Expansion style={{ background: "#007F8C" }} name={"UNTERHALTUNG & KULTUR"} />
            <Expansion style={{ background: "#05558D" }} name={"SPORT & FREIZEIT"} />
            <Expansion style={{ background: "#033F8C" }} name={"WELLNESS & GESUNDHEIT"} />
            <Expansion style={{ background: "#4e01a5" }} name={"EVENTS"} />
          </div>
        </Fade>
        {/* </Slide> */}
      </TabPanel>
      {/* TAB 2 ********************************************************************/}
      <TabPanel style={{ paddingTop: 0 }} value={value} index={1} dir={theme.direction}>
        {/* <Slide direction="left" in={true} mountOnEnter unmountOnExit> */}
        <Fade in={true}>
          <GridTest />
        </Fade>
        {/* </Slide>{" "} */}
      </TabPanel>
      {/* TAB 3******************************************************************* */}
      <TabPanel style={{ paddingTop: 0 }} value={value} index={2} dir={theme.direction}>
        {/* <Slide direction="left" in={true} mountOnEnter unmountOnExit> */}
        <Fade in={true}>
          <Paper>
            <New />
            <GridTest />
          </Paper>
        </Fade>
        {/* </Slide>{" "} */}
      </TabPanel>
    </div>
  );
}
