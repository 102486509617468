import React from "react";
import { Link } from "react-router-dom";
import { stores } from "./DataShopping";

export default () => {
  return (
    <div className="list">
      <ul>
        {stores.map((store, index) => {
          return (
            <div key={store.id}>
              {/* unique id for dynamic routing */}
              <Link to={`/store/${store.id}`}>
                <li>{store.name}</li>
              </Link>
            </div>
          );
        })}
      </ul>
    </div>
  );
};
