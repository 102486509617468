import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import Home from './components/Home'
import New from './components/New'
import Greet from './components/Greet'
import Nav from './components/Nav'
import Test from './components/Test'
import Tabs from './components/Tabs'
import Store from './components/Store'
import { Container } from '@material-ui/core';
import Kategorien from './components/Kategorien'



function App() {



  return (
    <BrowserRouter>
      <Container maxWidth='md'>
        <Nav />
        <Switch>
          <Route path='/' exact={true} component={Tabs} />
          {/* <Route path='/' exact={true} component={Home} /> */}
          <Route path='/home' exact={true} component={Test} />
          <Route path='/kat' exact={true} component={Kategorien} />
          <Route path='/new' exact={true} component={New} />
          <Route path='/greet/:name' exact={true} component={Greet} />
          {/* for dynamic routing use unique id! but check how to do with names */}
          <Route path='/store/:id' exact={true} component={Store} />
          {/* 404 ohne exact und ganz unten  */}
          <Route path='/' render={() => <div>404 Page not found!</div>} />

        </Switch>

      </Container>


    </BrowserRouter>
  );
}




export default App;
