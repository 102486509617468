import React, { useState } from "react";
import "./expansion.css";
import { Button } from "@material-ui/core";
import List from "./List";

export default function Expansion({ style, name }) {
  let [toggle, setToggle] = useState(true);

  function btnClicked() {
    if (toggle == true) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  }

  return (
    <div style={style} className="container1">
      <div className="title">
        <p className="kategorieName" onClick={btnClicked}>
          {name}
        </p>
      </div>
      <div className={toggle ? "toggle" : "toggle-open"}>
        <List />
      </div>
    </div>
  );
}
